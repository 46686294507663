<template>
	<div class="HomePage">
		<div class="homekuai">
			<div class="index-top">
				<div class="index-title">
					<div class="title">数据展示</div>
				</div>
				<div class="index-prompt">
					<div class="text"></div>
				</div>
			</div>
			<div class="home-main">
				<div @click="ontupath('/dataShow')" class="dan">
					消耗成本数据大屏
				</div>
			</div>
		</div>
		<div class="homekuai">
			<div class="index-top">
				<div class="index-title">
					<div class="title">工具</div>
				</div>
				<div class="index-prompt">
					<div class="text"></div>
				</div>
			</div>
			<div class="home-main">
				<div @click="ontupath('/workList')" class="dan">
					自助复审工具
				</div>
				<div @click="ontupath('/counter')" class="dan">
					充值计算器
				</div>
				<div @click="ontupath('/rorcounter')" class="dan ">
					出价盈亏计算器
				</div>
				<div @click="ontupath('')" class="dan node">
					一键垫款工具
				</div>
				<div @click="ontupath('/transfer')" class="dan ">
					跨端口实时转款
				</div>
				<div @click="ontupath('/advances')" class="dan ">
					充值工具
				</div>
				<div @click="ontupath('')" class="dan node">
					一键落地页搬家
				</div>
				<div @click="ontupath('/ratio')" class="dan">
					投产比计算器
				</div>
				<div @click="ontupath('')" class="dan node">
					视频素材文字提取工具
				</div>
			</div>
		</div>
		<div class="homekuai">
			<div class="index-top">
				<div class="index-title">
					<div class="title">我的产品</div>
				</div>
				<div class="index-prompt">
					<div class="text"></div>
				</div>
			</div>
			<div class="home-main">
				<div @click="ontupath('')" class="dan node">
					运营官（暂未绑定）
				</div>
				<div @click="ontupath('')" class="dan node">
					垫款额度（暂无额度）
				</div>
				<div @click="ontupath('')" class="dan node">
					积分管理器
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	data () {
		return {
			
		}
	},
	//跳转路由监听
	

	mounted(){
		//有token
		if(this.$route.query.token && !localStorage.getItem('TZ-USER')){
			this.ongetOrderList()
		}
		// // 有缓存信息跳转页面
	},

	methods: {
		// 自助充值列表
		ongetOrderList(val){
			var prame = {
				page:val
			}
			this.$service.get(this.$api.getOrderList,prame, (res)=> {
				if(res.code == '200'){
					this.OrderList = res.data.data
					this.lvtotal = res.data.total
				}
			})
		},
		
		ontupath(val){
			if(val == ''){
				this.$message({message: '暂未上线，敬请期待！', type: 'warning'})
			}else{
				this.$router.push(val)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.HomePage{
	.homekuai{
		padding:20px 0;
		border-bottom: 1px solid #ededed;
		&:first-child{
			padding-top: 0;
		}
		
	}
	.index-top{
		border: none;
		.index-title{
			padding-bottom: 0px;
		}
	}
	.home-main{
		display: flex;
		flex-wrap: wrap;
		.dan{
			margin: 10px 0;
			font-size: 18px;
			color: #444;
			padding: 10px 25px;
			margin-right: 20px;
			background: #f9fafb;
			box-shadow: 0px 0px 4px 1px rgba(0,0,0 ,.08);
			min-width: 160px;
			cursor: pointer;
			&.node{
				color: #b1b1b1;
				background: #f3f3f3;
				box-shadow:none
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.HomePage{
		.home-main{
			.dan{
				font-size: .3rem;
				min-width: 2.3rem;
				margin: .2rem;
				margin-left: 0;
				padding: .2rem;
			}
		}
	}
}
</style>

